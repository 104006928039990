/**
 * Created by lijinfeng on 2019/1/16.
 */
import axios from 'axios'
import { Message } from 'element-ui'

const baseURL = process.env.VUE_APP_BASE_API_URL
const request = axios.create({
  baseURL,
  timeout: 600000
})

// 请求
request.interceptors.request.use(
  config => {
    return config
  },
  error => {
      console.log('请求错误')
      console.log(error)
      return Promise.reject(error)
  }
)

// 返回
request.interceptors.response.use(
  response => {
    const res = response.data
    if (response && response.request && response.request.responseType === 'text') {
      return res
    }
    if (res.type === 'application/octet-stream') {
      return response.data
    }
    if (res.status === 1001) {
      Message({
        message: '登录过期，请重新登录',
        type: 'error',
        duration: 5 * 1000
      })
      setTimeout(async() => {
        await store.dispatch('logout')
      }, 5 * 1000)
    }
    if (res.status !== 0 && res.status !== 3) {
      if (res.msg !== undefined && res.msg !== null) {
        Message({
          message: res.msg,
          type: 'error',
          duration: 5 * 1000
        })
      }
      return Promise.reject(response.data)
    } else {
      return response.data
    }
  },
  error => {
    console.log(error)
    return Promise.reject(error.data)
  }
)

export default request
