import Vue from "vue";
import Router from "vue-router";
import api from "@/api/index";
import config from "@/utils/config";
Vue.use(Router);


const router = new Router({
  mode: 'history',
  routes: [{
    path: "/",
    name: "container",
    component: resolve =>
      require(['@/components/container.vue'], resolve),
    meta: {
      title: '首页'
    },
    redirect() {
      return '/home'
    },
    children: [
      // 首页
      {
        path: "/home",
        name: "home",
        component: resolve =>
          require(['@/views/home.vue'], resolve),
        meta: {
          title: '首页'
        }
      },
      {
        path: "/about",
        name: "about",
        component: resolve =>
          require(['@/views/about.vue'], resolve),
        meta: {
          title: '关于我们'
        }
      },
      {
        path: "/concat",
        name: "concat",
        component: resolve =>
          require(['@/views/concat.vue'], resolve),
        meta: {
          title: '联系我们'
        }
      },
      {
        path: "/news",
        name: "news",
        component: resolve =>
          require(['@/views/news.vue'], resolve),
        meta: {
          title: '新闻中心'
        }
      },
      {
        path: "/newsDetails",
        name: "newsDetails",
        component: resolve =>
          require(['@/views/newsDetails.vue'], resolve),
        meta: {
          title: '新闻中心详情'
        }
      },
      {
        path: "/quality",
        name: "quality",
        component: resolve =>
          require(['@/views/quality.vue'], resolve),
        meta: {
          title: '质量保障'
        }
      },
      {
        path: "/marketing",
        name: "marketing",
        component: resolve =>
          require(['@/views/marketing.vue'], resolve),
        meta: {
          title: '营销网络'
        }
      },
      {
        path: "/series",
        name: "series",
        component: resolve =>
          require(['@/views/series.vue'], resolve),
        meta: {
          title: '产品中心'
        }
      },
      ,
      {
        path: "/seriesDetail",
        name: "seriesDetail",
        component: resolve =>
          require(['@/views/seriesDetail.vue'], resolve),
        meta: {
          title: '产品中心详情'
        }
      },
    ]
  }]
})

router.afterEach((to) => {
  document.title = `山东五创工程机械有限公司_五创机械-- ${to.meta.title} ` || '山东五创工程机械有限公司_五创机械'
})

router.beforeEach((to, from, next) => {
  console.log(to)
  if (to.meta) {
    let modelType = null;
    if (to.meta.title === '关于我们') {
      modelType = 7
    } else if (to.meta.title === '首页') {
      modelType = 1
    } else if (to.meta.title === '产品中心') {
      modelType = 3
    } else if (to.meta.title === '新闻中心') {
      modelType = 2
    } else if (to.meta.title === '销售网络') {
      modelType = 5
    } else if (to.meta.title === '质量保证') {
      modelType = 6
    } else if (to.meta.title === '联系我们') {
      modelType = 7
    }
    const data = {
      siteId: config.siteId,
      modelType: modelType // 首页
    }
    api.channelInfoByModelType(data).then(res => {
      if (res.data) {
        const searchEngine = res.data.searchEngine
        let this_meta=to.matched[to.matched.length-1].meta
        if(this_meta.title)document.title=this_meta.title
        let head = document.getElementsByTagName('head');
        let meta_keyword=document.createElement('meta');
        if (searchEngine.keyWords) {
          if(document.querySelector('meta[name="keywords"]')){
            document.querySelector('meta[name="keywords"]').setAttribute('content',searchEngine.keyWords ? searchEngine.keyWords : '')
          }else{
            meta_keyword.setAttribute('name','keywords')
            meta_keyword.setAttribute('content',searchEngine.keyWords)
            head[0].appendChild(meta_keyword)
          }
        }
        if (searchEngine.description) {
          let meta_description=document.createElement('meta');
          if(document.querySelector('meta[name="description"]')){
            document.querySelector('meta[name="description"]').setAttribute('content', searchEngine.description ? searchEngine.description : '')
          }else{
            meta_description.setAttribute('name','description')
            meta_description.setAttribute('content',searchEngine.description)
            head[0].appendChild(meta_description)
          }
        }
      }
      next()
    })
  } else {
    next()
  }

})



export default router
