import Vue from 'vue'
import App from './App.vue'
import router from "./router";
import 'lib-flexible';
import ElementUI from 'element-ui'
// import 'element-ui/lib/theme-chalk/index.css'

Vue.use(ElementUI)

Vue.config.productionTip = false

function setRem() {
  const rem = document.documentElement.clientWidth / 10;
  document.documentElement.style.fontSize = rem + 'px';
}
// 初始化时调用一次
setRem();

// 监听窗口大小变化时重新计算
window.addEventListener('resize', setRem);

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
