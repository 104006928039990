import request from "@/utils/request";
import config from "@/utils/config";

const siteId = config.siteId;
const baseApi = '/api/open/web-site'

export default {
    /**
     * 频道树
     */
    channelAll() {
        return request({
            url: `${baseApi}/channel-all/${siteId}`,
            method: 'get',
        })
    },
    /**
     * 频道明细
     */
    channelInfoByModelType(data) {
        return request({
            url: `${baseApi}/channel-info`,
            method: 'post',
            data
        })
    },
    /**
     * 频道列表
     */
    channelProductList(data) {
        return request({
            url: `${baseApi}/channel-product-list`,
            method: 'post',
            data
        })
    },
    /**
     * 推荐商品
     */
    suggestProductList(channelId) {
        return request({
            url: `${baseApi}/suggest-product/${channelId}`,
            method: 'get'
        })
    },
    productInfo(id) {
        return request({
            url: `${baseApi}/product/${id}`,
            method: 'get'
        })
    },
    channelNewsList(data) {
        return request({
            url: `${baseApi}/channel-news-list`,
            method: 'post',
            data
        })
    },
    findNewsById(id) {
        return request({
            url: `${baseApi}/news/${id}`,
            method: 'get'
        })
    },
    findTop3NewsList(data) {
        return request({
            url: `${baseApi}/findTop3NewsList`,
            method: 'post',
            data
        })
    },
    /**
     * 置顶的商品列表
     */
    findTopProductList(data) {
        return request({
            url: `${baseApi}/findTopProductList`,
            method: 'post',
            data
        })
    },
    findNewsTopAndNext(id) {
        return request({
            url: `${baseApi}/findNewsTopAndNext/${id}`,
            method: 'get',
        })
    }
}

